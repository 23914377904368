<template>
	<MhRouterView class="OvExhibitionView view background2" :class="elmClasses" :isContentReady="isContentReady">

		<!-- default case -->
		<div class="minHeight minHeight--100 vSpace vSpace--viewContent" v-if="!is404">

			<OvMainHeadline
				:post="post"
			></OvMainHeadline>

			<OvSpacer type="default" :rows="6"></OvSpacer>
			<OvContentModules :post="post"></OvContentModules>

		</div>

		<!-- 404 case -->
		<div class="minHeight minHeight--100 vSpace vSpace--viewContent" v-else>

			<Ov404></Ov404>

		</div>

		<OvMainFooter></OvMainFooter>

		<!--
		<pre data-name="post">{{post}}</pre>
		-->

	</MhRouterView>
</template>

<script>
	// @ is an alias to /src
	import EventBus from '@/helper/EventBus.js' // TODO: remove this
	import RestHandler from '@/components/RestHandler/RestHandler.js'

	import MhRouterView from '@/components/MhRouterView/MhRouterView.vue'
	import OvMainHeader from '@/components/OvMainHeader.vue'
	import OvMainHeadline from '@/components/OvMainHeadline.vue'
	import OvContentModules from '@/components/OvContentModules.vue'
	import OvMainFooter from '@/components/OvMainFooter.vue'
	import OvSpacer from '@/components/OvSpacer.vue'
	import Ov404 from '@/components/Ov404.vue'

	export default {
		name: 'OvExhibitionView',
		components: {
			MhRouterView,
			OvMainHeader,
			OvMainHeadline,
			OvContentModules,
			OvMainFooter,
			OvSpacer,
			Ov404,
		},
		mixins: [ RestHandler ],
		directives: {},
		props: {},
		data() {
			return {
				post: {},
				isContentReady : false,
				is404 : false,
			}
		},
		watch: {
			restHandler__pendingRequests: {
				handler( to, from, doLog = false ) {
					const icon = to ? '❌' : '✅'

					if( doLog ){
						console.groupCollapsed(this.$options.name, '• watch restHandler__pendingRequests', from, to, icon)
						console.groupEnd()
					}

					if( !to ) this.isContentReady = true
				},
				immediate : true,
			},
		},
		computed: {
			app() {
				return this.$root.$children[0]
			},
			elmClasses(){
				let classes = []

				if( this.isRunningMainExhibition ){
					classes.push('OvExhibitionView--isRunning')
					classes.push('background2--color-primary-base')
				}else{
					classes.push('background2--color-primary-background')
				}

				return classes
			},
			isRunningMainExhibition(){
				let startDate   	 = this._.get(this.post, 'acf.startDate')
					startDate   	 = parseInt(this.app.replaceAll( startDate, '-', '' ))
				let endDate     	 = this._.get(this.post, 'acf.endDate')
					endDate     	 = parseInt(this.app.replaceAll( endDate, '-', '' ))
				let currentDate 	 = parseInt(this.app.dateToYYYYMMDD( Date() ))
				let isRunning   	 = (startDate <= currentDate && endDate >= currentDate) ? true : false
				let isMainExhibition = this._.get(this.post, 'acf.category') === 1 ? true : false

				return isRunning && isMainExhibition
			},
		},
		methods: {
			fetchPost(){
				this.restHandler__fetch({
					action : 'GET',
					route : '/wp-json/mh/v1/posts',
					params : {
						slug : this.$route.params.slug
					},
					callback : (response) => {
						const result = response.data.result[0]

						this.post = result
						this.is404 = result ? false : true
					},
				})
			}
		},
		mounted() {
			// fetch exhibition
			this.fetchPost()
		},
	}
</script>

<style lang="less">
	@import (reference) "@/less/vars.less";
	@import (reference) "@/less/mixins.less";

	.OvExhibitionView {
		// für laufende veranstaltungen wird eine kräftigerer Hintergrundfarbe verwendet.
		// dafür müssen daraufliegende elemente ebenfalls dunkler dargestellt werden
		// damit am schluss der kontrast wieder stimmt.
		// super, diese css-variablen!
		&--isRunning {
			--color-primary-backgroundDarker : var(--color-primary-baseDarker);
			--color-primary-baseLink : var(--color-primary-baseLinkDarker);
		}
	}

	@media @mq[xs] {}
	@media @mq[sm] {}
	@media @mq[md] {}
	@media @mq[dt] {}
	@media @mq[lg] {}
	@media @mq[xl] {}
</style>
